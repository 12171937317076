import { useCamera } from 'hooks';
import { createContext, useContext } from 'react';

const CameraContext = createContext();
const CameraProvider = ({ children }) => {
  const props = useCamera();

  return (
    <CameraContext.Provider value={props}>{children}</CameraContext.Provider>
  );
};
export const useCameraContext = () => {
  const context = useContext(CameraContext);
  if (!context) throw Error('Not have context');
  return context;
};

export default CameraProvider;
