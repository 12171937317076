import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  getUsers,
  getUserDetail,
  putUser,
  postUser,
  putUserStatus,
  putPasswordForUser,
  putUserPassword,
  resetPasswordForUser,
  getUserMyInfo,
  getUserGroup,
  getUserGroupDetail,
  putUserGroup,
  getUserRole,
  getUserDepartment,
} from './userAPI';

const initialState = {
  users: undefined,
  detail: undefined,
  status: 'idle',
  error: undefined,
  userGroup: undefined,
  detailGroup: undefined,
  userDepartment: undefined,
};

export const getUsersAsync = createAsyncThunk(
  'users',
  async (params, thunkAPI) => {
    try {
      const response = await getUsers(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const getUserDetailAsync = createAsyncThunk(
  'userDetail',
  async (id, thunkAPI) => {
    try {
      const response = await getUserDetail(id, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const getUserMyInfoAsync = createAsyncThunk(
  'userMyInfo',
  async (id, thunkAPI) => {
    try {
      const response = await getUserMyInfo(id, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const postUserAsync = createAsyncThunk(
  'postUser',
  async (data, thunkAPI) => {
    try {
      const response = await postUser(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const putUserAsync = createAsyncThunk(
  'putUser',
  async (data, thunkAPI) => {
    try {
      const response = await putUser(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const putUserStatusAsync = createAsyncThunk(
  'putUserStatus',
  async (data, thunkAPI) => {
    try {
      const response = await putUserStatus(data.id, data.values);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const putUserPasswordAsync = createAsyncThunk(
  'putUserPassword',
  async (data, thunkAPI) => {
    try {
      const response = await putUserPassword(data);
      localStorage.removeItem('auth');
      localStorage.removeItem('token');
      window.location.replace('/');
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  },
);
export const putPasswordForUserAsync = createAsyncThunk(
  'putPasswordForUser',
  async (data, thunkAPI) => {
    try {
      const response = await putPasswordForUser(data.id, data.values);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const resetPasswordForUserAsync = createAsyncThunk(
  'resetPassword',
  async (id, thunkAPI) => {
    try {
      const response = await resetPasswordForUser(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const getUserGroupAsync = createAsyncThunk(
  'user-group',
  async (params, thunkAPI) => {
    try {
      const response = await getUserGroup(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const getUserGroupDetailAsync = createAsyncThunk(
  'userGroupDetail',
  async (id, thunkAPI) => {
    try {
      const response = await getUserGroupDetail(id, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const putUserGroupAsync = createAsyncThunk(
  'putUserGroup',
  async (data, thunkAPI) => {
    try {
      const response = await putUserGroup(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const getUserRoleAsync = createAsyncThunk(
  'user-role',
  async (params, thunkAPI) => {
    try {
      const response = await getUserRole(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const getUserDepartmentAsync = createAsyncThunk(
  'user-department',
  async (params, thunkAPI) => {
    try {
      const response = await getUserDepartment(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = 'idle';
    },
    clearUserDetail: (state) => {
      state.detail = undefined;
    },
    clearUsers: (state) => {
      state.users = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUsersAsync.fulfilled, (state, action) => {
        state.users = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getUsersAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getUsers',
        };
      })
      .addCase(getUserDetailAsync.pending, (state) => {
        state.status = 'detail_loading';
      })
      .addCase(getUserDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getUserDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getUser',
        };
      })
      .addCase(getUserMyInfoAsync.pending, (state) => {
        state.status = 'detail_loading';
      })
      .addCase(getUserMyInfoAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getUserMyInfoAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getUserMyInfo',
        };
      })
      .addCase(postUserAsync.pending, (state) => {
        state.status = 'detail_loading';
      })
      .addCase(postUserAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putUserAsync.pending, (state) => {
        state.status = 'update_loading';
      })
      .addCase(putUserAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success('Cập nhật người dùng thành công!');
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putUserAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Cập nhật người dùng thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'putUser',
        };
      })
      .addCase(putUserStatusAsync.pending, (state) => {
        state.status = 'update_status_loading';
      })
      .addCase(putUserStatusAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putUserStatusAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Đổi trạng thái thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'putUserStatus',
        };
      })
      .addCase(putUserPasswordAsync.pending, (state) => {
        state.status = 'update_password_loading';
      })
      .addCase(putUserPasswordAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putUserPasswordAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          if (action?.payload?.status === 403) {
            const data = action.payload.data[0];
            if (data.message === 'Không đúng mật khẩu') {
              message.error('Không đúng mật khẩu!');
            }
          } else {
            message.error('Đổi mật khẩu thất bại!');
          }
          state.error = {
            payload: action.payload,
            title: 'putUserPassword',
          };
        }
      })
      .addCase(putPasswordForUserAsync.pending, (state) => {
        state.status = 'update_other_password_loading';
      })
      .addCase(putPasswordForUserAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putPasswordForUserAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Đổi mật khẩu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'putOtherUserPassword',
        };
      })

      .addCase(resetPasswordForUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(resetPasswordForUserAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success('Khôi phúc mật khẩu thành công!');
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(resetPasswordForUserAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Khôi phục mật khẩu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'resetPassword',
        };
      })
      .addCase(getUserGroupAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserGroupAsync.fulfilled, (state, action) => {
        state.userGroup = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getUserGroupAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getUserGroup',
        };
      })
      .addCase(getUserGroupDetailAsync.pending, (state) => {
        state.status = 'detail_group_loading';
      })
      .addCase(getUserGroupDetailAsync.fulfilled, (state, action) => {
        state.detailGroup = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getUserGroupDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getDetailGroup',
        };
      })
      .addCase(putUserGroupAsync.pending, (state) => {
        state.status = 'update_loading';
      })
      .addCase(putUserGroupAsync.fulfilled, (state, action) => {
        state.detailGroup = action.payload;
        message.success('Cập nhật nhóm người dùng thành công!');
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putUserGroupAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Cập nhật nhóm người dùng thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'putUserGroup',
        };
      })
      .addCase(getUserRoleAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserRoleAsync.fulfilled, (state, action) => {
        state.userGroup = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getUserRoleAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getUserRole',
        };
      })
      .addCase(getUserDepartmentAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserDepartmentAsync.fulfilled, (state, action) => {
        state.userDepartment = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getUserDepartmentAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getUserDepartment',
        };
      });
  },
});
export const selectUsers = (state) => state.users.users;
export const selectUserDetail = (state) => state.users.detail;
export const usersStatus = (state) => state.users.status;
export const usersError = (state) => state.users.error;
export const selectUserGroup = (state) => state.users.userGroup;
export const selectUserGroupDetail = (state) => state.users.detailGroup;
export const selectUserRole = (state) => state.users.userGroup;
export const selectUserDepartment = (state) => state.users.userDepartment;

export const { clearUserDetail, clearUsers, setIdleStatus } = userSlice.actions;

export default userSlice.reducer;
