const CAMERA_HEIGHT = 480;

const CAMERA_STATUS = {
  NO_DEVICE_CAMERA: 0,
  LOADING: 1,
  LOADED: 2,
};
const COUNTDOWN_STATUS = {
  NONE: 0,
  LOADING: 1,
  LOADED: 2,
};

export { CAMERA_HEIGHT, CAMERA_STATUS, COUNTDOWN_STATUS };
