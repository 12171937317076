import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const reportVolumnPoint = (params, thunkAPI) => {
  return axiosClient.get('reporting/volume-points', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const reportUnit = (params, thunkAPI) => {
  return axiosClient.get('reporting/units', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const reportPurchaser = (params, thunkAPI) => {
  return axiosClient.get('reporting/purchasers', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const reportUnitPerPurchaser = (params, thunkAPI) => {
  return axiosClient.get('reporting/units-per-purchasers', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const reportPenetration = (params, thunkAPI) => {
  return axiosClient.get('reporting/penetration', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export {
  reportVolumnPoint,
  reportUnit,
  reportPurchaser,
  reportUnitPerPurchaser,
  reportPenetration,
};
