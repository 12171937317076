import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  getCheckin,
  getCheckinDetail,
  putCheckin,
  postCheckin,
} from './checkinAPI';

const initialState = {
  list: undefined,
  detail: undefined,
  status: 'idle',
  error: undefined,
};

export const getCheckinAsync = createAsyncThunk(
  'checkin',
  async (params, thunkAPI) => {
    try {
      const response = await getCheckin(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getCheckinDetailAsync = createAsyncThunk(
  'checkinDetail',
  async (id, thunkAPI) => {
    try {
      const response = await getCheckinDetail(id, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const postCheckinAsync = createAsyncThunk(
  'postCheckin',
  async (data, thunkAPI) => {
    try {
      const response = await postCheckin(data, thunkAPI);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const putCheckinAsync = createAsyncThunk(
  'putCheckin',
  async (data, thunkAPI) => {
    try {
      const response = await putCheckin(data, thunkAPI);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const checkinSlice = createSlice({
  name: 'checkin',
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = 'idle';
    },
    clearCheckinDetail: (state) => {
      state.detail = undefined;
    },
    clearCheckin: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getCheckinAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCheckinAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getCheckinAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getCheckin',
        };
      })
      .addCase(getCheckinDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCheckinDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getCheckinDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getCheckinDetail',
        };
      })
      .addCase(postCheckinAsync.pending, (state) => {
        state.status = 'detail_loading';
      })
      .addCase(postCheckinAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(postCheckinAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lỗi thành viên chưa mua gói này!');
        }
        state.error = {
          payload: action.payload,
          title: 'addCheckinDetail',
        };
      })
      .addCase(putCheckinAsync.pending, (state) => {
        state.status = 'update_loading';
      })
      .addCase(putCheckinAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success('Cập nhật thành công!');
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putCheckinAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Cập nhật thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'putCheckin',
        };
      });
  },
});

export const selectCheckin = (state) => state.checkin.list;
export const selectCheckinDetail = (state) => state.checkin.detail;
export const checkinStatus = (state) => state.checkin.status;
export const checkinError = (state) => state.checkin.error;

export const { clearCheckinDetail, clearCheckin, setIdleStatus } =
  checkinSlice.actions;

export default checkinSlice.reducer;
