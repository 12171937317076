import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

// const instance = axios.create({
//   baseURL: process.env.REACT_APP_API + "v2/admin-panel/",
// });

const getMasterImportList = (params, thunkAPI) => {
  return axiosClient.get('admin-import', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getMasterImportListTrackandTrace = (params, thunkAPI) => {
  return axiosClient.get('trackandtraces/order/import', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getDownloadTemplate = (params, thunkAPI) => {
  return axiosClient.get('admin-import/template', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getTrackAndTracesTemplate = (params, thunkAPI) => {
  return axiosClient.get('trackandtraces/template', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postCheckFile = (data) => {
  return axiosClient.post('trackandtraces/order/check-import-gift', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postMasterImport = (url, data) => {
  if (url === 'member' || url === 'nc') {
    return axiosClient.post('admin-import-v2/' + url, data, {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    });
  } else if (url === 'trackandtraces') {
    return axiosClient.post(url + '/order/import', data, {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    });
  } else {
    return axiosClient.post('admin-import/' + url, data, {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    });
  }
};
const postMasterImportTrackAndTrace = (url, data) => {
  if (url === 'trackandtraces') {
    return axiosClient.post(url + '/order/import', data, {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    });
  }
  if (url === 'gift') {
    return axiosClient.post('trackandtraces/order/import-gift', data, {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    });
  }
  return axiosClient.post('trackandtraces/import/' + url, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export {
  getDownloadTemplate,
  getMasterImportList,
  getMasterImportListTrackandTrace,
  getTrackAndTracesTemplate,
  postCheckFile,
  postMasterImport,
  postMasterImportTrackAndTrace,
};
