import {
  createAsyncThunk,
  // createAsytopicThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { message } from 'antd';
import { activeVersion, getVersions, putVersion } from './versionAPI';

const initialState = {
  list: undefined,
  detail: undefined,
  status: 'idle',
  error: undefined,
};

export const getVersionsAsync = createAsyncThunk(
  'versions',
  async (params, thunkAPI) => {
    try {
      const response = await getVersions(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const putVersionAsync = createAsyncThunk(
  'putVersion',
  async (data, thunkAPI) => {
    try {
      const response = await putVersion(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const activeVersionAsync = createAsyncThunk(
  'activeVersion',
  async (data, thunkAPI) => {
    try {
      const response = await activeVersion(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const versionSlice = createSlice({
  name: 'version',
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = 'idle';
    },
    clearVersionDetail: (state) => {
      state.detail = undefined;
    },
    clearVersions: (state) => {
      state.list = undefined;
    },
    updateVersionDetail: (state, action) => {
      state.detail = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // ieventluding actions generated by createAsyeventThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getVersionsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getVersionsAsync.fulfilled, (state, action) => {
        state.list = action.payload.result;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getVersionsAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getVersions',
        };
      })
      .addCase(putVersionAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(putVersionAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putVersionAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Cập nhật thất bại');
        }
        state.error = {
          payload: action.payload,
          title: 'putVersion',
        };
      })
      .addCase(activeVersionAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(activeVersionAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(activeVersionAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Cập nhật thất bại');
        }
        state.error = {
          payload: action.payload,
          title: 'activeVersion',
        };
      });
  },
});

export const selectVersions = (state) => state.versions.list;
export const versionsStatus = (state) => state.versions.status;
export const versionsError = (state) => state.versions.error;

export const {
  clearVersionDetail,
  clearVersions,
  setIdleStatus,
  updateVersionDetail,
} = versionSlice.actions;

export default versionSlice.reducer;
