import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  getTopicDetail,
  getTopics,
  postTopic,
  putStatusTopic,
} from './topicAPI';

const initialState = {
  list: undefined,
  detail: undefined,
  status: 'idle',
  error: undefined,
};

export const getTopicsAsync = createAsyncThunk(
  'topics',
  async (params, thunkAPI) => {
    try {
      const response = await getTopics(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getTopicDetailAsync = createAsyncThunk(
  'topicDetail',
  async (id, thunkAPI) => {
    try {
      const response = await getTopicDetail(id, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const postTopicAsync = createAsyncThunk(
  'postTopic',
  async (data, thunkAPI) => {
    try {
      const response = await postTopic(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const putTopicStatusAsync = createAsyncThunk(
  'putTopicStatus',
  async (data, thunkAPI) => {
    try {
      const response = await putStatusTopic(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const topicSlice = createSlice({
  name: 'topic',
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = 'idle';
    },
    clearTopicDetail: (state) => {
      state.detail = undefined;
    },
    clearTopics: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // itopicluding actions generated by createAsytopicThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getTopicsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTopicsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getTopicsAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getTopics',
        };
      })
      .addCase(getTopicDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTopicDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getTopicDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getTopicDetail',
        };
      })
      .addCase(postTopicAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postTopicAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(postTopicAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Thêm mới bài viết thất bại');
        }
        state.error = {
          payload: action.payload,
          title: 'addTopic',
        };
      })
      .addCase(putTopicStatusAsync.pending, (state) => {
        state.status = 'update_status_loading';
      })
      .addCase(putTopicStatusAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putTopicStatusAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Đổi trạng thái thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'putTopicStatus',
        };
      });
  },
});

export const selectTopics = (state) => state.topics.list;
export const selectTopicDetail = (state) => state.topics.detail;
export const topicsStatus = (state) => state.topics.status;
export const topicsError = (state) => state.topics.error;

export const { clearTopicDetail, clearTopics, setIdleStatus } =
  topicSlice.actions;

export default topicSlice.reducer;
