import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getEvents = (params, thunkAPI) => {
  return axiosClient.get('event', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getEventDetail = (id, thunkAPI) => {
  return axiosClient.get('event/' + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postEvent = (data) => {
  return axiosClient.post('event', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putEvent = (id, data) => {
  return axiosClient.patch('event/' + id, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putStatusEvent = (data) => {
  return axiosClient.patch(
    `event/${data?.id}/status/${data?.values?.status}`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    },
  );
};

const exportEvent = (params, options) => {
  return axiosClient.get('event/export', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const deleteEventImage = (id, fileUploadId) => {
  return axiosClient.delete(`event/${id}/picture/${fileUploadId}`, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export {
  getEvents,
  getEventDetail,
  postEvent,
  putEvent,
  putStatusEvent,
  exportEvent,
  deleteEventImage,
};
