import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getNCCs = (params, thunkAPI) => {
  return axiosClient.get('members', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getNCCDetail = ({ id, isUsingNutritionApi = false }, thunkAPI) => {
  return axiosClient.get(
    `${isUsingNutritionApi ? 'nutrition-clubs/member/' : 'members/'}` + id,
    {
      signal: thunkAPI?.signal,
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    },
  );
};

const postNCC = (data) => {
  return axiosClient.post('members', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getNCs = (params, thunkAPI) => {
  return axiosClient.get('nutrition-clubs', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putNCC = (data) => {
  return axiosClient.patch('members/' + data.id, data.values, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getType = (params, thunkAPI) => {
  return axiosClient.get('enums/nutrition-club-customer-levers', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

// const exportNCCs = (params, options) => {
//   return axiosClient.get("members/export", {
//     params,
//     ...options,
//     headers: {
//       Authorization: "Bearer " + getToken(),
//     },
//   });
// };

const exportNCCs = (params, options) => {
  return axiosClient.get('nutrition-clubs/exportv2', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const exportPresenters = (params, options) => {
  return axiosClient.get('members/referral/export', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getPresenterNCCs = (params, thunkAPI) => {
  return axiosClient.get('members/referral', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getRelativeNCCs = (memberId, params, thunkAPI) => {
  return axiosClient.get(`members/${memberId}/relative`, {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const deleteRelativeNCC = (relativeId) => {
  return axiosClient.delete(`members/relative/${relativeId}`, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postRelativeNCC = (memberId, data) => {
  return axiosClient.post(`members/${memberId}/relative`, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putRelativeNCC = (data) => {
  return axiosClient.patch('members/relative/' + data.relativeId, data.values, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getNCCReport = (params, thunkAPI) => {
  return axiosClient.get('reporting/tab-report', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const exportNCCReport = (params, options) => {
  return axiosClient.get('reporting/tab-report-export', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export {
  getNCCs,
  getNCCDetail,
  postNCC,
  getNCs,
  putNCC,
  getType,
  exportNCCs,
  getPresenterNCCs,
  exportPresenters,
  getRelativeNCCs,
  deleteRelativeNCC,
  postRelativeNCC,
  putRelativeNCC,
  getNCCReport,
  exportNCCReport,
};
