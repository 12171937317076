import {
  createAsyncThunk,
  // createAsynoticationThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { message } from 'antd';
import { getNotiDetail, getNotis, postNoti, putStatusNoti } from './notiAPI';

const initialState = {
  list: {
    data: [{ id: 1, title: 'Dữ liệu cứng', status: 1 }],
    total: 1,
  },
  detail: undefined,
  status: 'idle',
  error: undefined,
  //bell
  countNoti: 2,
  listNoti: [
    {
      id: 1,
      title: 'Thông báo 1',
      category: { id: 1, value: '' },
      is_unread: false,
      unread: -1,
    },
    {
      id: 2,
      title: 'Thông báo 2 sự kiện',
      category: { id: 3, value: '12' },
      is_unread: true,
      unread: 1,
    },
    {
      id: 3,
      title: 'Thông báo 3 bài viết',
      category: { id: 2, value: '1' },
      is_unread: true,
      unread: 1,
    },
  ],
  loadingNoti: false,
  errorNoti: false,
  totalUnread: 1,
  totalNotify: 10,
};

export const getNotisAsync = createAsyncThunk(
  'notications',
  async (params, thunkAPI) => {
    try {
      const response = await getNotis(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getNotiDetailAsync = createAsyncThunk(
  'noticationDetail',
  async (id, thunkAPI) => {
    try {
      const response = await getNotiDetail(id, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const postNotiAsync = createAsyncThunk(
  'postNoti',
  async (data, thunkAPI) => {
    try {
      const response = await postNoti(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const putNotiStatusAsync = createAsyncThunk(
  'putNotiStatus',
  async (data, thunkAPI) => {
    try {
      const response = await putStatusNoti(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const notiSlice = createSlice({
  name: 'notication',
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = 'idle';
    },
    clearNotiDetail: (state) => {
      state.detail = undefined;
    },
    clearNotis: (state) => {
      state.list = undefined;
    },
    updateNotiDetail: (state, action) => {
      state.detail = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // inoticationluding actions generated by createAsynoticationThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getNotisAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNotisAsync.fulfilled, (state, action) => {
        state.list = action.payload.result;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getNotisAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getNotis',
        };
      })
      .addCase(getNotiDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNotiDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getNotiDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getNotiDetail',
        };
      })
      .addCase(postNotiAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postNotiAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(postNotiAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Create Noti failed');
        }
        state.error = {
          payload: action.payload,
          title: 'addNoti',
        };
      })
      .addCase(putNotiStatusAsync.pending, (state) => {
        state.status = 'update_status_loading';
      })
      .addCase(putNotiStatusAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putNotiStatusAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Cập nhật trạng thái thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'putNotiStatus',
        };
      });
  },
});

export const selectNotis = (state) => state.notification.list;
export const selectNotiDetail = (state) => state.notification.detail;
export const notiStatus = (state) => state.notification.status;
export const notiError = (state) => state.notification.error;

export const { clearNotiDetail, clearNotis, setIdleStatus, updateNotiDetail } =
  notiSlice.actions;

export default notiSlice.reducer;
