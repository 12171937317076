import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  getAreas,
  getChartData,
  getDashboardOverview,
  getNewsFeedDetail,
  getNewsFeedOfficial,
  getNewsFeedTeam,
  getNewsFeedTeamOfficial,
  getRounds,
  getStatistic,
  getStatisticMember,
  getStatisticRound,
  getStatisticRoundPhase,
  getSubChartOverview,
  getTeamDetail,
  getTeamStockPhoto,
  getTeams,
} from './newme2023Api';

const initialState = {
  herbalifePosts: undefined,
  memberPosts: undefined,
  postDetail: undefined,
  teams: undefined,
  teamDetail: undefined,
  rounds: undefined,
  statistic: undefined,
  chartData: undefined,
  stockPhoto: undefined,
  newsFeedTeam: undefined,
  areas: undefined,
  dashboardOverview: undefined,
  dashboardOverviewStatus: 'idle',
  subChartOverview: undefined,
  subChartOverviewStatus: 'idle',
  statisticRound: undefined,
  statisticRoundStatus: 'idle',
  statisticRoundPhase: undefined,
  statisticRoundPhaseStatus: 'idle',
  statisticMember: undefined,
  statisticMemberStatus: 'idle',
  status: 'idle',
  error: undefined,
};

export const getHerbalifePostsAsync = createAsyncThunk(
  'herbalifePosts',
  async (params, thunkApi) => {
    try {
      const { data } = await getNewsFeedOfficial(params);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getMemberPostsAsync = createAsyncThunk(
  'memberPosts',
  async (params, thunkApi) => {
    try {
      const { data } = await getNewsFeedTeamOfficial(params);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getPostDetailAsync = createAsyncThunk(
  'postDetail',
  async (params, thunkApi) => {
    try {
      const { data } = await getNewsFeedDetail(params);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getTeamsAsync = createAsyncThunk(
  'teams',
  async (params, thunkApi) => {
    try {
      const { data } = await getTeams(params);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getTeamDetailAsync = createAsyncThunk(
  'teamDetail',
  async (params, thunkApi) => {
    try {
      const { data } = await getTeamDetail(params);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getRoundsAsync = createAsyncThunk(
  'rounds',
  async (params, thunkApi) => {
    try {
      const { data } = await getRounds(params);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getStatisticAsync = createAsyncThunk(
  'statistic',
  async (params, thunkApi) => {
    try {
      const { data } = await getStatistic(params);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getChartDataAsync = createAsyncThunk(
  'chartData',
  async (params, thunkApi) => {
    try {
      const { data } = await getChartData(params);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getTeamStockPhotoAsync = createAsyncThunk(
  'teamStockPhoto',
  async (params, thunkApi) => {
    try {
      const { data } = await getTeamStockPhoto(params);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getNewsFeedTeamAsync = createAsyncThunk(
  'newsFeedTeam',
  async (params, thunkApi) => {
    try {
      const { data } = await getNewsFeedTeam(params);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getDashboardOverviewAsync = createAsyncThunk(
  'dashboardOverview',
  async (_, thunkApi) => {
    try {
      const { data } = await getDashboardOverview();
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getSubChartOverviewAsync = createAsyncThunk(
  'subChartOverview',
  async (params, thunkApi) => {
    try {
      const { data } = await getSubChartOverview(params);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getAreasAsync = createAsyncThunk('areas', async (_, thunkApi) => {
  try {
    const { data } = await getAreas();
    return data;
  } catch (error) {
    return thunkApi.rejectWithValue(error.response.data);
  }
});

export const getStatisticRoundAsync = createAsyncThunk(
  'statisticRound',
  async (params, thunkApi) => {
    try {
      const { data } = await getStatisticRound(params);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getStatisticRoundPhaseAsync = createAsyncThunk(
  'statisticRoundPhase',
  async (params, thunkApi) => {
    try {
      const { data } = await getStatisticRoundPhase(params);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getStatisticMemberAsync = createAsyncThunk(
  'statisticMember',
  async (_, thunkApi) => {
    try {
      const { data } = await getStatisticMember();
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const newme2023Slice = createSlice({
  name: 'newme2023',
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = 'idle';
    },
    clearHerbalifePosts: (state) => {
      state.herbalifePosts = undefined;
    },
    clearMemberPosts: (state) => {
      state.memberPosts = undefined;
    },
    clearPostDetail: (state) => {
      state.postDetail = undefined;
    },
    clearTeams: (state) => {
      state.teams = undefined;
    },
    clearTeamDetail: (state) => {
      state.teamDetail = undefined;
    },
    clearRounds: (state) => {
      state.rounds = undefined;
    },
    clearStatistic: (state) => {
      state.statistic = undefined;
    },
    clearChartData: (state) => {
      state.chartData = undefined;
    },
    clearTeamStockPhoto: (state) => {
      state.stockPhoto = undefined;
    },
    clearNewsFeedTeam: (state) => {
      state.newsFeedTeam = undefined;
    },
    clearAreas: (state) => {
      state.areas = undefined;
    },
    clearDashboardOverview: (state) => {
      state.dashboardOverview = undefined;
    },
    clearDashboardOverviewStatus: (state) => {
      state.dashboardOverviewStatus = undefined;
    },
    clearSubChartOverview: (state) => {
      state.subChartOverview = undefined;
    },
    clearSubChartOverviewStatus: (state) => {
      state.subChartOverviewStatus = undefined;
    },
    clearStatisticRound: (state) => {
      state.statisticRound = undefined;
    },
    clearStatisticRoundStatus: (state) => {
      state.statisticRoundStatus = undefined;
    },
    clearStatisticRoundPhase: (state) => {
      state.statisticRoundPhase = undefined;
    },
    clearStatisticRoundPhaseStatus: (state) => {
      state.statisticRoundPhaseStatus = undefined;
    },
    clearStatisticMember: (state) => {
      state.statisticMember = undefined;
    },
    clearStatisticMemberStatus: (state) => {
      state.statisticMemberStatus = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHerbalifePostsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getHerbalifePostsAsync.fulfilled, (state, action) => {
        state.herbalifePosts = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getHerbalifePostsAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = { payload: action.payload, title: 'getHerbalifePosts' };
        }
      })
      .addCase(getMemberPostsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMemberPostsAsync.fulfilled, (state, action) => {
        state.memberPosts = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getMemberPostsAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = { payload: action.payload, title: 'getMemberPosts' };
        }
      })
      .addCase(getPostDetailAsync.pending, (state) => {
        state.status = 'detail_loading';
      })
      .addCase(getPostDetailAsync.fulfilled, (state, action) => {
        state.postDetail = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getPostDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = { payload: action.payload, title: 'getPostDetail' };
        }
      })
      .addCase(getTeamsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTeamsAsync.fulfilled, (state, action) => {
        state.teams = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getTeamsAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = { payload: action.payload, title: 'getTeams' };
        }
      })
      .addCase(getTeamDetailAsync.pending, (state) => {
        state.status = 'detail_loading';
      })
      .addCase(getTeamDetailAsync.fulfilled, (state, action) => {
        state.teamDetail = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getTeamDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = { payload: action.payload, title: 'getTeamDetail' };
        }
      })
      .addCase(getRoundsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getRoundsAsync.fulfilled, (state, action) => {
        state.rounds = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getRoundsAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = { payload: action.payload, title: 'getRounds' };
        }
      })
      .addCase(getStatisticAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStatisticAsync.fulfilled, (state, action) => {
        state.statistic = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getStatisticAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = { payload: action.payload, title: 'getStatistic' };
        }
      })
      .addCase(getChartDataAsync.pending, (state) => {
        state.status = 'chart_loading';
      })
      .addCase(getChartDataAsync.fulfilled, (state, action) => {
        state.chartData = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getChartDataAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = { payload: action.payload, title: 'getChartData' };
        }
      })
      .addCase(getTeamStockPhotoAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTeamStockPhotoAsync.fulfilled, (state, action) => {
        state.stockPhoto = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getTeamStockPhotoAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = { payload: action.payload, title: 'getTeamStockPhoto' };
        }
      })
      .addCase(getNewsFeedTeamAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNewsFeedTeamAsync.fulfilled, (state, action) => {
        state.newsFeedTeam = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getNewsFeedTeamAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = { payload: action.payload, title: 'getNewsFeedTeam' };
        }
      })
      .addCase(getDashboardOverviewAsync.pending, (state) => {
        state.dashboardOverviewStatus = 'loading';
      })
      .addCase(getDashboardOverviewAsync.fulfilled, (state, action) => {
        state.dashboardOverview = action.payload;
        state.dashboardOverviewStatus = 'idle';
        state.error = undefined;
      })
      .addCase(getDashboardOverviewAsync.rejected, (state, action) => {
        state.dashboardOverviewStatus = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = {
            payload: action.payload,
            title: 'getDashboardOverview',
          };
        }
      })
      .addCase(getSubChartOverviewAsync.pending, (state) => {
        state.subChartOverviewStatus = 'loading';
      })
      .addCase(getSubChartOverviewAsync.fulfilled, (state, action) => {
        state.subChartOverview = action.payload;
        state.subChartOverviewStatus = 'idle';
        state.error = undefined;
      })
      .addCase(getSubChartOverviewAsync.rejected, (state, action) => {
        state.subChartOverviewStatus = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = {
            payload: action.payload,
            title: 'getSubChartOverview',
          };
        }
      })
      .addCase(getAreasAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAreasAsync.fulfilled, (state, action) => {
        state.areas = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getAreasAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = {
            payload: action.payload,
            title: 'getAreas',
          };
        }
      })
      .addCase(getStatisticRoundAsync.pending, (state) => {
        state.statisticRoundStatus = 'loading';
      })
      .addCase(getStatisticRoundAsync.fulfilled, (state, action) => {
        state.statisticRound = action.payload;
        state.statisticRoundStatus = 'idle';
        state.error = undefined;
      })
      .addCase(getStatisticRoundAsync.rejected, (state, action) => {
        state.statisticRoundStatus = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = {
            payload: action.payload,
            title: 'getStatisticRound',
          };
        }
      })
      .addCase(getStatisticRoundPhaseAsync.pending, (state) => {
        state.statisticRoundPhaseStatus = 'loading';
      })
      .addCase(getStatisticRoundPhaseAsync.fulfilled, (state, action) => {
        state.statisticRoundPhase = action.payload;
        state.statisticRoundPhaseStatus = 'idle';
        state.error = undefined;
      })
      .addCase(getStatisticRoundPhaseAsync.rejected, (state, action) => {
        state.statisticRoundPhaseStatus = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = {
            payload: action.payload,
            title: 'getStatisticRoundPhase',
          };
        }
      })
      .addCase(getStatisticMemberAsync.pending, (state) => {
        state.statisticMemberStatus = 'loading';
      })
      .addCase(getStatisticMemberAsync.fulfilled, (state, action) => {
        state.statisticMember = action.payload;
        state.statisticMemberStatus = 'idle';
        state.error = undefined;
      })
      .addCase(getStatisticMemberAsync.rejected, (state, action) => {
        state.statisticMemberStatus = 'idle';
        if (
          action.error.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
          state.error = {
            payload: action.payload,
            title: 'getStatisticMember',
          };
        }
      });
  },
});

export const selectHerbalifePosts = (state) => state.newme2023.herbalifePosts;
export const selectMemberPosts = (state) => state.newme2023.memberPosts;
export const selectPostDetail = (state) => state.newme2023.postDetail;
export const newme2023Status = (state) => state.newme2023.status;
export const newme2023Error = (state) => state.newme2023.error;
export const selectTeams = (state) => state.newme2023.teams;
export const selectTeamDetail = (state) => state.newme2023.teamDetail;
export const selectRounds = (state) => state.newme2023.rounds;
export const selectStatistic = (state) => state.newme2023.statistic;
export const selectChartData = (state) => state.newme2023.chartData;
export const selectStockPhoto = (state) => state.newme2023.stockPhoto;
export const selectNewsFeedTeam = (state) => state.newme2023.newsFeedTeam;
export const selectAreas = (state) => state.newme2023.areas;
export const selectDashboardOverview = (state) =>
  state.newme2023.dashboardOverview;
export const selectDashboardOverviewStatus = (state) =>
  state.newme2023.dashboardOverviewStatus;
export const selectSubChartOverview = (state) =>
  state.newme2023.subChartOverview;
export const selectSubChartOverviewStatus = (state) =>
  state.newme2023.subChartOverviewStatus;
export const selectStatisticRound = (state) => state.newme2023.statisticRound;
export const selectStatisticRoundStatus = (state) =>
  state.newme2023.statisticRoundStatus;
export const selectStatisticRoundPhase = (state) =>
  state.newme2023.statisticRoundPhase;
export const selectStatisticRoundPhaseStatus = (state) =>
  state.newme2023.statisticRoundPhaseStatus;
export const selectStatisticMember = (state) => state.newme2023.statisticMember;
export const selectStatisticMemberStatus = (state) =>
  state.newme2023.statisticMemberStatus;

export const {
  setIdleStatus,
  clearHerbalifePosts,
  clearMemberPosts,
  clearPostDetail,
  clearTeams,
  clearTeamDetail,
  clearRounds,
  clearStatistic,
  clearChartData,
  clearTeamStockPhoto,
  clearNewsFeedTeam,
  clearAreas,
  clearDashboardOverview,
  clearDashboardOverviewStatus,
  clearSubChartOverview,
  clearSubChartOverviewStatus,
  clearStatisticRound,
  clearStatisticRoundStatus,
  clearStatisticRoundPhase,
  clearStatisticRoundPhaseStatus,
  clearStatisticMember,
  clearStatisticMemberStatus,
} = newme2023Slice.actions;

export default newme2023Slice.reducer;
