import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  getHotNewsMember,
  getNewsById,
  getNewsList,
  patchNews,
} from './newsApi';

const initialState = {
  list: undefined,
  detail: undefined,
  status: 'idle',
  error: undefined,
  members: undefined,
  membersStatus: undefined,
};

export const getNewsListAsync = createAsyncThunk(
  'newsList',
  async (params, thunkApi) => {
    try {
      const { data } = await getNewsList(params, thunkApi);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getNewsDetailAsync = createAsyncThunk(
  'newsdetail',
  async (id, thunkApi) => {
    try {
      const { data } = await getNewsById(id, thunkApi);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const updateNewsAsync = createAsyncThunk(
  'updateNews',
  async ({ id, data }, thunkApi) => {
    try {
      const { data: news } = await patchNews(id, data);
      return news;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const getHotNewsMemberAsync = createAsyncThunk(
  'hotNewsMember',
  async (params, thunkApi) => {
    try {
      const { data } = await getHotNewsMember(params);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response.data);
    }
  },
);

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = 'idle';
    },
    clearNewsDetail: (state) => {
      state.detail = undefined;
    },
    clearNews: (state) => {
      state.list = undefined;
    },
    clearMembers: (state) => {
      state.members = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewsListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNewsListAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getNewsListAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getNewsList',
        };
      })
      .addCase(getNewsDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNewsDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getNewsDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getNewsDetail',
        };
      })
      .addCase(updateNewsAsync.pending, (state) => {
        state.status = 'update_loading';
      })
      .addCase(updateNewsAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'idle';
        state.error = undefined;
        message.success('Cập nhật tin tức thành công');
      })
      .addCase(updateNewsAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Cập nhật tin tức thất bại');
        }
        state.error = {
          payload: action.payload,
          title: 'updateNews',
        };
      })
      .addCase(getHotNewsMemberAsync.pending, (state) => {
        state.membersStatus = 'loading';
      })
      .addCase(getHotNewsMemberAsync.fulfilled, (state, action) => {
        state.members = action.payload;
        state.membersStatus = 'idle';
        state.error = undefined;
      })
      .addCase(getHotNewsMemberAsync.rejected, (state, action) => {
        state.membersStatus = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getHotNewsMember',
        };
      });
  },
});

export const selectNews = (state) => state.news.list;
export const selectNewsDetail = (state) => state.news.detail;
export const newsStatus = (state) => state.news.status;
export const newsError = (state) => state.news.error;
export const selectMembers = (state) => state.news.members;
export const selectMembersStatus = (state) => state.news.membersStatus;

export const { clearNews, clearNewsDetail, setIdleStatus, clearMembers } =
  newsSlice.actions;

export default newsSlice.reducer;
