import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getNewsList = (params, thunkApi) => {
  return axiosClient.get('hot-hews', {
    signal: thunkApi?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postNews = (data) => {
  return axiosClient.post('hot-hews', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

const patchNews = (id, data) => {
  return axiosClient.patch('hot-hews/' + id, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getNewsById = (id, thunkApi) => {
  return axiosClient.get('hot-hews/' + id, {
    signal: thunkApi?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getHotNewsMember = (params) => {
  return axiosClient.get('hot-hews/hot-news-member', {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const updateHotNewsMember = (data) => {
  return axiosClient.patch('hot-hews/hot-news-member', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
      'Content-Type': 'multipart/form-data',
    },
  });
};

export {
  getNewsById,
  getNewsList,
  patchNews,
  postNews,
  getHotNewsMember,
  updateHotNewsMember,
};
