import LoadingComponent from 'components/LoadingComponent';
import React, { Suspense, useLayoutEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import './App.css';
import { useCameraContext } from 'providers/CameraProvider';

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Login = React.lazy(() => import('./containers/Login'));
const PermissionBeside = React.lazy(
  () => import('./containers/beside/permission/PermissionBeside'),
);

function App() {
  const location = useLocation();
  const { show, setShow } = useCameraContext();

  useLayoutEffect(() => {
    if (show) {
      return () => {
        setShow(false);
      };
    }
  }, [location, setShow, show]);

  return (
    <Suspense fallback={<LoadingComponent inline />}>
      <Switch>
        <Redirect exact from="/" to="/login" />
        <Route exact path="/login" component={Login} />
        <Route exact path="/beside/permission" component={PermissionBeside} />
        <Route path="/:locale" component={DefaultLayout} />
      </Switch>
    </Suspense>
  );
}

export default App;
