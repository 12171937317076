import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getCategories = (params, thunkAPI) => {
  return axiosClient.get('/categories', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getCategoryDetail = (id, thunkAPI) => {
  return axiosClient.get('/categories/' + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postCategory = (data) => {
  return axiosClient.post('/categories', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putCategory = (data) => {
  return axiosClient.patch('/categories/' + data.id, data.values, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export { getCategories, getCategoryDetail, postCategory, putCategory };
