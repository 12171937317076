import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getUsers = (params, thunkAPI) => {
  return axiosClient.get('member-credentials/v2', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getUserDetail = (id, thunkAPI) => {
  return axiosClient.get('member-credentials/v2/' + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getUserMyInfo = (id, thunkAPI) => {
  return axiosClient.get('authentication/my-info', {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postUser = (data) => {
  return axiosClient.post('member-credentials/v2', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putUser = (data) => {
  return axiosClient.patch('member-credentials/v2/' + data.id, data.values, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putUserStatus = (memberCredentialId, data) => {
  return axiosClient.patch(
    `/member-credentials/${memberCredentialId}/status`,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    },
  );
};

const putUserPassword = (data) => {
  return axiosClient.post('authentication/change-password', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putPasswordForUser = (id, data) => {
  // return axiosClient.put("users-password/" + id, data, {
  //     headers: {
  //         Authorization: "Bearer " + getToken()
  //     }
  // })
};

const resetPasswordForUser = (id) => {
  return axiosClient.patch(
    `member-credentials/reset-password?memberCredentialId=${id}`,
    null,
    {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    },
  );
};
const getUserGroup = (params, thunkAPI) => {
  return axiosClient.get('roles/getall', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getUserGroupDetail = (id, thunkAPI) => {
  let url = ''
  // Dùng chỗ này để người dùng có thể xem thông tin của mình khi không có quyền
  // truy cập  api 'roles/+id' bên dưới.
  if( typeof id === "object" && id?.id){
    url = `authentication/role?roleId=${id.id}`
  }
  else {
    url = 'roles/' + id
  }
  return axiosClient.get(url, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const putUserGroup = (data) => {
  return axiosClient.patch('roles/' + data.id, data.values, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getUserRole = (params, thunkAPI) => {
  return axiosClient.get('authentication/get-roles', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getUserDepartment = (params, thunkAPI) => {
  return axiosClient.get('departments', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const confirmEmail = (params) => {
  return axiosClient.get('member-credentials/confirm-email', {
    params,
  });
};

const userApproval = ({ id, data }) => {
  return axiosClient.patch(`member-credentials/${id}/approval`, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export {
  confirmEmail,
  getUserDepartment,
  getUserDetail,
  getUserGroup,
  getUserGroupDetail,
  getUserMyInfo,
  getUserRole,
  getUsers,
  postUser,
  putPasswordForUser,
  putUser,
  putUserGroup,
  putUserPassword,
  putUserStatus,
  resetPasswordForUser,
  userApproval,
};
