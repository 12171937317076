import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getTopics = (params, thunkAPI) => {
  return axiosClient.get('topic', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getTopicDetail = (id, thunkAPI) => {
  return axiosClient.get('topic/' + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postTopic = (data) => {
  return axiosClient.post('topic', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putTopic = (id, data) => {
  return axiosClient.patch('topic/' + id, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putStatusTopic = (data) => {
  return axiosClient.patch(
    `topic/${data?.id}/status/${data?.values?.status}`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    },
  );
};

const exportTopic = (params, options) => {
  return axiosClient.get('topic/export', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const deleteTopicImage = (id, fileUploadId) => {
  return axiosClient.delete(`topic/${id}/picture/${fileUploadId}`, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export {
  getTopics,
  getTopicDetail,
  postTopic,
  putTopic,
  putStatusTopic,
  exportTopic,
  deleteTopicImage,
};
