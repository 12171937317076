import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getActivityLog = (params, thunkAPI) => {
  return axiosClient.get(`${params.url}/${params.id}/log`, {
    signal: thunkAPI?.signal,
    params: params.values,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export { getActivityLog };
