import {
  createAsyncThunk,
  // createAsytopicThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  getSimulationDetail,
  getSimulations,
  postSimulation,
  putSimulation,
} from './simulationAPI';

const initialState = {
  list: undefined,
  detail: undefined,
  status: 'idle',
  error: undefined,
};

export const getSimulationsAsync = createAsyncThunk(
  'simulations',
  async (params, thunkAPI) => {
    try {
      const response = await getSimulations(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getSimulationDetailAsync = createAsyncThunk(
  'eventDetail',
  async (id, thunkAPI) => {
    try {
      const response = await getSimulationDetail(id, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const postSimulationAsync = createAsyncThunk(
  'postSimulation',
  async (data, thunkAPI) => {
    try {
      const response = await postSimulation(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const putSimulationAsync = createAsyncThunk(
  'putSimulation',
  async (data, thunkAPI) => {
    try {
      const response = await putSimulation(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const simulationSlice = createSlice({
  name: 'simulation',
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = 'idle';
    },
    clearSimulationDetail: (state) => {
      state.detail = undefined;
    },
    clearSimulations: (state) => {
      state.list = undefined;
    },
    updateSimulationDetail: (state, action) => {
      state.detail = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // ieventluding actions generated by createAsyeventThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getSimulationsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSimulationsAsync.fulfilled, (state, action) => {
        state.list = action.payload.result;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getSimulationsAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getSimulations',
        };
      })
      .addCase(getSimulationDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSimulationDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        state.status = 'idle';
        state.error = false;
      })
      .addCase(getSimulationDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getSimulationDetail',
        };
      })
      .addCase(postSimulationAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postSimulationAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(postSimulationAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Create Simulation failed');
        }
        state.error = {
          payload: action.payload,
          title: 'addSimulation',
        };
      })
      .addCase(putSimulationAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(putSimulationAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putSimulationAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Cập nhật thất bại');
        }
        state.error = {
          payload: action.payload,
          title: 'addSimulation',
        };
      });
  },
});

export const selectSimulations = (state) => state.simulations.list;
export const selectSimulationDetail = (state) => state.simulations.detail;
export const simulationsStatus = (state) => state.simulations.status;
export const simulationsError = (state) => state.simulations.error;

export const {
  clearSimulationDetail,
  clearSimulations,
  setIdleStatus,
  updateSimulationDetail,
} = simulationSlice.actions;

export default simulationSlice.reducer;
