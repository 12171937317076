import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { getReportFile } from 'features/dashboard/dashboardApi';
import {
  getPackages,
  getPackageDetail,
  postPackage,
  putPackage,
  getPackageMember,
  getTop5Packages,
} from './packageAPI';

const initialState = {
  list: {
    data: [],
    total: 0,
  },
  topList: {
    data: [],
    total: 0,
  },
  reportFile: {
    data: [],
    total: 0,
  },
  total: 0,
  detail: {},
  status: 'idle',
  error: undefined,
};

export const getPackagesAsync = createAsyncThunk(
  'packages',
  async (params, thunkAPI) => {
    try {
      const response = await getPackages(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      // return thunkAPI.rejectWithValue(error.response.data)
    }
  },
);

export const getPackagesMemberAsync = createAsyncThunk(
  'packagesMember',
  async (params, thunkAPI) => {
    try {
      const response = await getPackageMember(params);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      // return thunkAPI.rejectWithValue(error.response.data)
    }
  },
);

export const getPackageDetailAsync = createAsyncThunk(
  'packageDetail',
  async (id, thunkAPI) => {
    try {
      const response = await getPackageDetail(id);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const postPackageAsync = createAsyncThunk(
  'postPackage',
  async (data, thunkAPI) => {
    try {
      const response = await postPackage(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const putPackageAsync = createAsyncThunk(
  'putPackage',
  async (data, thunkAPI) => {
    try {
      const response = await putPackage(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const putPackageStatusAsync = createAsyncThunk(
  'putPackageStatus',
  async (data, thunkAPI) => {
    try {
      const response = await putPackage(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getTop5PackagesAsync = createAsyncThunk(
  'top5Packages',
  async (params, thunkAPI) => {
    try {
      const response = await getTop5Packages(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      // return thunkAPI.rejectWithValue(error.response.data)
    }
  },
);

export const getReportFileAsync = createAsyncThunk(
  'reportfileDashboard',
  async (params, thunkAPI) => {
    try {
      const response = await getReportFile(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      // return thunkAPI.rejectWithValue(error.response.data)
    }
  },
);

export const packageSlice = createSlice({
  name: 'packages',
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = 'idle';
    },
    clearPackageDetail: (state) => {
      state.detail = undefined;
    },
    clearPackages: (state) => {
      state.list = initialState.list;
    },
    updatePackageList: (state, action) => {
      state.list = action.payload;
    },
    updatePackageDetail: (state, action) => {
      state.detail = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getPackagesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPackagesAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getPackagesAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getPackages',
        };
      })
      .addCase(getPackagesMemberAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPackagesMemberAsync.fulfilled, (state, action) => {
        let result = action.payload?.result;
        state.list = result;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getPackagesMemberAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getPackages',
        };
      })
      .addCase(getPackageDetailAsync.pending, (state) => {
        state.status = 'detail_loading';
      })
      .addCase(getPackageDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getPackageDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getPackageDetail',
        };
      })
      .addCase(postPackageAsync.pending, (state) => {
        state.status = 'detail_loading';
      })
      .addCase(postPackageAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putPackageAsync.pending, (state) => {
        state.status = 'update_loading';
      })
      .addCase(putPackageAsync.fulfilled, (state, action) => {
        state.detail = action.payload.result;
        message.success('Cập nhật gói dinh dưỡng thành công');
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putPackageAsync.rejected, (state, action) => {
        state.status = 'idle';
        message.error('Cập nhật gói dinh dưỡng thất bại');
        state.error = {
          payload: action.payload,
          title: 'putPackage',
        };
      })
      .addCase(putPackageStatusAsync.pending, (state) => {
        state.status = 'update_status_loading';
      })
      .addCase(putPackageStatusAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putPackageStatusAsync.rejected, (state, action) => {
        state.status = 'idle';
        message.error('Đổi trạng thái thất bại!');
        state.error = {
          payload: action.payload,
          title: 'putPackageStatus',
        };
      })
      .addCase(getTop5PackagesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTop5PackagesAsync.fulfilled, (state, action) => {
        state.topList = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getTop5PackagesAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getPackages',
        };
      })
      .addCase(getReportFileAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getReportFileAsync.fulfilled, (state, action) => {
        state.reportFile = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getReportFileAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getReportFile',
        };
      });
  },
});

export const selectPackages = (state) => state.packages.list;
export const selectTop5Packages = (state) => state.packages.topList;
export const selectPackageDetail = (state) => state.packages.detail;
export const selectReportFile = (state) => state.packages.reportFile;
export const packagesStatus = (state) => state.packages.status;
export const packagesError = (state) => state.packages.error;

export const {
  clearPackageDetail,
  clearPackages,
  setIdleStatus,
  updatePackageDetail,
} = packageSlice.actions;

export default packageSlice.reducer;
