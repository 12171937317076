import axiosClient from '../axiosClient';

const login = (data) => {
  return axiosClient.post('authentication/loginv2', data);
};

const resetPassword = (params) => {
  return axiosClient.get('authentication/reset-password', { params });
};

const changePassword = (token, data) => {
  return axiosClient.post('authentication/change-password', data, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  });
};

const logout = () => {
  return axiosClient.post(
    `authentication/logout?token=${
      localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken
    }`,
  );
};

const cancelRequestLogin = () => {
  return axiosClient.post(
    `authentication/cancel-login-other-devices?token=${
      localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken
    }`,
  );
};

export { login, resetPassword, changePassword, logout, cancelRequestLogin };
