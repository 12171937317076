import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getSimulations = (params, thunkAPI) => {
  return axiosClient.get('simulator', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getSimulationDetail = (id, thunkAPI) => {
  return axiosClient.get('simulator/' + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postSimulation = (data) => {
  return axiosClient.post('simulator', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putSimulation = ({ id, data }) => {
  return axiosClient.patch('simulator/' + id, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const deleteSimulation = (id) => {
  return axiosClient.delete('simulator/' + id, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const exportSimulation = (params, options) => {
  return axiosClient.get('simulator/export', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getRevenue = (data, thunkAPI) => {
  return axiosClient.post('simulator/revenue', data, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export {
  getSimulations,
  getSimulationDetail,
  postSimulation,
  putSimulation,
  deleteSimulation,
  exportSimulation,
  getRevenue,
};
