import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getInfoDashboard = (params, thunkAPI) => {
  return axiosClient.get('dashboard/information', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getHealthAmbassador = (params, thunkAPI) => {
  return axiosClient.get('statistics/representative-health-ambassador', {
    signal: thunkAPI?.signal,
    //dai su suc khoe tieu bieu
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getNotiMembers = (params, thunkAPI) => {
  return axiosClient.get('statistics/noticeable-members', {
    signal: thunkAPI?.signal,
    //thanh vien can chu y
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getRepresentativeMembers = (params, thunkAPI) => {
  return axiosClient.get('statistics/representative-member', {
    signal: thunkAPI?.signal,
    //thanh vien noi bat trong thang
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getRepresentativeCustomer = (params, thunkAPI) => {
  return axiosClient.get('statistics/representative-customer', {
    signal: thunkAPI?.signal,
    //Khachs hang noi bat trong thang
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getSoldPackages = (params, thunkAPI) => {
  return axiosClient.get('statistics/sold-packages', {
    signal: thunkAPI?.signal,
    //Tong goi dinh duong
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const exportDashboard = (data) => {
  return axiosClient.post('report/retail-sales', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getChartDashboard = (params, thunkAPI) => {
  return axiosClient.get('dashboard/chart', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getReportFile = (params, thunkAPI) => {
  return axiosClient.get('dashboard/report-file', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export {
  getInfoDashboard,
  getHealthAmbassador,
  getNotiMembers,
  getRepresentativeMembers,
  getRepresentativeCustomer,
  getSoldPackages,
  exportDashboard,
  getChartDashboard,
  getReportFile,
};
