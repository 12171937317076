import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getNCs = (params, thunkAPI) => {
  return axiosClient.get('nutrition-clubs', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getNCDetail = (id, thunkAPI) => {
  return axiosClient.get('nutrition-clubs/' + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postNC = (data) => {
  return axiosClient.post('nutrition-clubs', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putNC = (id, data) => {
  return axiosClient.patch('nutrition-clubs/' + id, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const exportNCs = (params, options) => {
  return axiosClient.get('nutrition-clubs/export', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getRequestNCs = (params, thunkAPI) => {
  return axiosClient.get('nutrition-club-update', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putRequestNC = (id, params) => {
  return axiosClient.patch(
    'nutrition-club-update/' + id,
    {},
    {
      params,
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    },
  );
};

export {
  getNCs,
  getNCDetail,
  postNC,
  putNC,
  exportNCs,
  getRequestNCs,
  putRequestNC,
};
