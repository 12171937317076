import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  getNCCDetail,
  getNCCReport,
  getNCCs,
  getPresenterNCCs,
  postNCC,
  putNCC,
} from './nccAPI';

const initialState = {
  list: undefined,
  detail: undefined,
  status: 'idle',
  error: undefined,
  presenter: undefined,
  listReport: undefined,
};

export const getNCCsAsync = createAsyncThunk(
  'nccs',
  async (params, thunkAPI) => {
    try {
      const response = await getNCCs(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getNCCDetailAsync = createAsyncThunk(
  'nccDetail',
  async ({ id, isUsingNutritionApi = false }, thunkAPI) => {
    try {
      const response = await getNCCDetail(
        { id, isUsingNutritionApi },
        thunkAPI,
      );
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const postNCCAsync = createAsyncThunk(
  'postNCC',
  async (data, thunkAPI) => {
    try {
      const response = await postNCC(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const putNCCAsync = createAsyncThunk(
  'putNCC',
  async (data, thunkAPI) => {
    try {
      const response = await putNCC(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getPresenterNCCsAsync = createAsyncThunk(
  'presenterNccs',
  async (params, thunkAPI) => {
    try {
      const response = await getPresenterNCCs(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getNCCReportAsync = createAsyncThunk(
  'nccReport',
  async (params, thunkAPI) => {
    try {
      const response = await getNCCReport(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const nccSlice = createSlice({
  name: 'ncc',
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = 'idle';
    },
    clearNCCDetail: (state) => {
      state.detail = undefined;
    },
    clearNCCs: (state) => {
      state.list = undefined;
    },
    clearPresenterNCCs: (state) => {
      state.presenter = undefined;
    },
    clearNCCReport: (state) => {
      state.listReport = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getNCCsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNCCsAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getNCCsAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getNCCs',
        };
      })
      .addCase(getNCCDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNCCDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getNCCDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getNCCDetail',
        };
      })
      .addCase(postNCCAsync.pending, (state) => {
        state.status = 'detail_loading';
      })
      .addCase(postNCCAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(postNCCAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Thêm mới Hội Viên thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'addNCCDetail',
        };
      })
      .addCase(putNCCAsync.pending, (state) => {
        state.status = 'update_loading';
      })
      .addCase(putNCCAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        message.success('Cập nhật Hội Viên thành công!');
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putNCCAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Cập nhật Hội Viên thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'putNCC',
        };
      })
      .addCase(getPresenterNCCsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPresenterNCCsAsync.fulfilled, (state, action) => {
        state.presenter = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getPresenterNCCsAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getNCCs',
        };
      })
      .addCase(getNCCReportAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNCCReportAsync.fulfilled, (state, action) => {
        state.listReport = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getNCCReportAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getNCCs',
        };
      });
  },
});

export const selectNCCs = (state) => state.ncc.list;
export const selectNCCReport = (state) => state.ncc.listReport;
export const selectPresenterNCCs = (state) => state.ncc.presenter;
export const selectNCCDetail = (state) => state.ncc.detail;
export const nccsStatus = (state) => state.ncc.status;
export const nccsError = (state) => state.ncc.error;

export const {
  clearNCCDetail,
  clearNCCs,
  setIdleStatus,
  clearPresenterNCCs,
  clearNCCReport,
} = nccSlice.actions;

export default nccSlice.reducer;
