import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getPackages = (params, thunkAPI) => {
  return axiosClient.get('package', {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
    signal: thunkAPI?.signal,
  });
};

const getPackageMember = (idMember, thunkAPI) => {
  return axiosClient.get('ncc-packages/members/' + idMember, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getPackageDetail = (id, thunkAPI) => {
  return axiosClient.get('package/' + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getPackagesByUnit = (params, thunkAPI) => {
  return axiosClient.get('packages-detail', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postPackage = (data) => {
  return axiosClient.post('package', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putPackage = (data) => {
  return axiosClient.patch('package/' + data.id, data.values, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putPackageImage = (id, data) => {
  return axiosClient.patch('package/' + id, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putPackageStatus = (id, data) => {
  return axiosClient.put('packages-status/' + id, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const removeProductPackage = (packageId, productGroupId) => {
  return axiosClient.delete(
    `/package/${packageId}/product-group/${productGroupId}`,
    {
      headers: { Authorization: 'Bearer ' + getToken() },
    },
  );
};

const addProductPackage = (packageId, payload) => {
  return axiosClient.post(`package/${packageId}/products`, payload, {
    headers: { Authorization: 'Bearer ' + getToken() },
  });
};

const exportPackages = (params, options) => {
  return axiosClient.get('package/export', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const deleteProductPackageImage = (id, fileUploadId) => {
  return axiosClient.delete(`package/${id}/picture/${fileUploadId}`, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getPricePointPackages = (params, thunkAPI) => {
  return axiosClient.post('package/price-point', params, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getTop5Packages = (params, thunkAPI) => {
  return axiosClient.get('dashboard/top-package', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getTop5Products = (params, thunkAPI) => {
  return axiosClient.get('dashboard/top-product', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export {
  getPackages,
  getPackageDetail,
  postPackage,
  putPackage,
  putPackageStatus,
  putPackageImage,
  getPackagesByUnit,
  getPackageMember,
  removeProductPackage,
  addProductPackage,
  exportPackages,
  deleteProductPackageImage,
  getPricePointPackages,
  getTop5Packages,
  getTop5Products,
};
