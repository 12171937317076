import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  getInfoDashboard,
  getHealthAmbassador,
  getNotiMembers,
  getRepresentativeMembers,
  getRepresentativeCustomer,
  getSoldPackages,
} from './dashboardApi';
import _ from 'lodash';

const initialState = {
  healthAmbassador: undefined,
  notiMembers: undefined,
  representMembers: undefined,
  representCustomer: undefined,
  soldPackages: undefined,
  info: undefined,
  status: 'idle',
  error: undefined,
};

export const getInfoDashboardAsync = createAsyncThunk(
  'getInfoDashboard',
  async (params, thunkAPI) => {
    try {
      const response = await getInfoDashboard(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data?.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getHealthAmbassadorAsync = createAsyncThunk(
  'getHealthAmbassador',
  async (params, thunkAPI) => {
    try {
      const response = await getHealthAmbassador(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data?.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getNotiMembersAsync = createAsyncThunk(
  'getNotiMembers',
  async (params, thunkAPI) => {
    try {
      const response = await getNotiMembers(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data?.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getRepresentativeCustomerAsync = createAsyncThunk(
  'getRepresentativeCustomer',
  async (params, thunkAPI) => {
    try {
      const response = await getRepresentativeCustomer(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data?.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getRepresentativeMembersAsync = createAsyncThunk(
  'getRepresentativeMembers',
  async (params, thunkAPI) => {
    try {
      const response = await getRepresentativeMembers(params, thunkAPI);
      return response.data?.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const getSoldPackagesAsync = createAsyncThunk(
  'getSoldPackages',
  async (params, thunkAPI) => {
    try {
      const response = await getSoldPackages(params, thunkAPI);
      return response.data?.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // setIdleStatus: state => {
    //     state.status = "idle"
    // },
    // clearCustomerDetail: state => {
    //     state.detail = undefined;
    // },
    // clearCustomers: state => {
    //     state.list = undefined;
    // }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInfoDashboardAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getInfoDashboardAsync.fulfilled, (state, action) => {
        state.info = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getInfoDashboardAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getInfoDashboard',
        };
      })
      .addCase(getHealthAmbassadorAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getHealthAmbassadorAsync.fulfilled, (state, action) => {
        state.healthAmbassador = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getHealthAmbassadorAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getCustomers',
        };
      })
      .addCase(getRepresentativeMembersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getRepresentativeMembersAsync.fulfilled, (state, action) => {
        state.representMembers = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getRepresentativeMembersAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getCustomers',
        };
      })
      .addCase(getSoldPackagesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSoldPackagesAsync.fulfilled, (state, action) => {
        let array = action.payload.data;
        let totalPackageSold = _.sumBy(array, 'totalSold');
        state.soldPackages = totalPackageSold;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getSoldPackagesAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getCustomers',
        };
      })
      .addCase(getRepresentativeCustomerAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getRepresentativeCustomerAsync.fulfilled, (state, action) => {
        state.representCustomer = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getRepresentativeCustomerAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getCustomers',
        };
      })
      .addCase(getNotiMembersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNotiMembersAsync.fulfilled, (state, action) => {
        state.notiMembers = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getNotiMembersAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getCustomers',
        };
      });
  },
});

export const healthAmbassador = (state) => state.dashboard.healthAmbassador;
export const notiMembers = (state) => state.dashboard.notiMembers;
export const representMembers = (state) => state.dashboard.representMembers;
export const representCustomer = (state) => state.dashboard.representCustomer;
export const soldPackages = (state) => state.dashboard.soldPackages;
export const selectInfo = (state) => state.dashboard.info;

// export const { clearCustomerDetail, clearCustomers, setIdleStatus } = customerSlice.actions;

export default dashboardSlice.reducer;
