import _ from 'lodash';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { createFilterFromUrl, renderURL } from 'utils';

const useFilter = ({ config, initFilter, pageKey = 'Page' }) => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const filterFromUrl = createFilterFromUrl(urlParams, config);

  const cleanFilter = _.pickBy(filterFromUrl);

  const [filter, setFilter] = useState({
    ...initFilter,
    ...cleanFilter,
  });

  const onPaginationChange = (page) => {
    const newFilter = { ...filter, [pageKey]: page };
    setFilter(newFilter);
    history.push({
      pathname: location.pathname,
      search: renderURL('', newFilter),
    });
  };

  return { filter, setFilter, onPaginationChange, filterFromUrl };
};

export default useFilter;
