import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getCheckin = (params, thunkAPI) => {
  return axiosClient.get('check-in', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getCheckinDetail = (id, thunkAPI) => {
  return axiosClient.get('check-in/' + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postCheckin = (data, thunkAPI) => {
  return axiosClient.post('check-in', data, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putCheckin = (data, thunkAPI) => {
  return axiosClient.put('check-in/' + data.id, data.values, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const deleteCheckin = (id, thunkAPI) => {
  return axiosClient.delete('check-in/' + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getPackages = (params, thunkAPI) => {
  return axiosClient.get('packages', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getSession = (params, thunkAPI) => {
  return axiosClient.get('enums/check-in-sessions', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getMember = (params, thunkAPI) => {
  return axiosClient.get('check-in/members-not-yet-checkin', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getMemberList = (params, thunkAPI) => {
  return axiosClient.get('members', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const exportCheckin = (params, options) => {
  return axiosClient.get('check-in/export', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const spreadCheckin = (params, options) => {
  return axiosClient.get('check-in/spread-success', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export {
  getCheckin,
  postCheckin,
  getCheckinDetail,
  putCheckin,
  deleteCheckin,
  getPackages,
  getSession,
  getMember,
  getMemberList,
  exportCheckin,
  spreadCheckin,
};
