import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getProvinces = (params, thunkAPI) => {
  return axiosClient.get('province', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getDistricts = (provinceId, params, thunkAPI) => {
  return axiosClient.get(`province/${provinceId}/district`, {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getWards = ({ districtId, params, signal }) => {
  return axiosClient.get(`province/${districtId}/wards`, {
    signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export { getProvinces, getDistricts, getWards };
