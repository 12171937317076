import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getSurveys = (params, thunkAPI) => {
  return axiosClient.get('surveys', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getSurveyDetail = (id, thunkAPI) => {
  return axiosClient.get('surveys/' + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postSurvey = (data) => {
  return axiosClient.post('surveys', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putSurvey = (id, data) => {
  return axiosClient.patch('surveys/' + id + '/update', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const exportSurvey = (params, options) => {
  return axiosClient.get('surveys/export', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
    responseType: 'blob',
  });
};

const deleteSurveyImage = (id, fileUploadId) => {
  return axiosClient.delete(`surveys/${id}/picture/${fileUploadId}`, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getStatisticSurvey = (id, thunkAPI) => {
  return axiosClient.post(
    `surveys/${id}/member-statistic`,
    {},
    {
      signal: thunkAPI?.signal,
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    },
  );
};

const getQuestionListSurvey = (params, thunkAPI) => {
  return axiosClient.post(
    `surveys/quiz-statistic?SurveyId=${params.SurveyId}&Page=${params.Page}&PageCount=${params.PageCount}`,
    {},
    {
      signal: thunkAPI?.signal,
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    },
  );
};

const getMemberListSurvey = (params, thunkAPI) => {
  return axiosClient.get(`surveys/${params.id}/member-survey`, {
    signal: thunkAPI?.signal,
    params: params.value,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getMemberListFileSurvey = (params, thunkAPI) => {
  return axiosClient.get(`surveys/${params.id}/custom-member-survey`, {
    signal: thunkAPI?.signal,
    params: params.value,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const exportQuesSurvey = (id, options) => {
  return axiosClient.get(`surveys/${id}/export`, {
    params: { download: true },
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
    responseType: 'blob',
  });
};

export {
  getSurveys,
  getSurveyDetail,
  postSurvey,
  putSurvey,
  exportSurvey,
  deleteSurveyImage,
  getStatisticSurvey,
  getQuestionListSurvey,
  getMemberListSurvey,
  exportQuesSurvey,
  getMemberListFileSurvey,
};
