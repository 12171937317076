import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

export const generalConfig = {
  baseURL: process.env.REACT_APP_API + 'v1/admin-panel/weight-loss/',
  headers: {
    Authorization: 'Bearer ' + getToken(),
  },
};

const getWeightLoss = (params, thunkAPI) => {
  return axiosClient.get('weight-loss', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getWeightLossGroup = (params, thunkAPI) => {
  return axiosClient.get('weight-loss/groups', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getWeightLossDashboard = (params, thunkAPI) => {
  return axiosClient.get('weight-loss/dashboard', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getWeightLossDetail = (id, params, thunkAPI) => {
  return axiosClient.get('weight-loss/' + id, {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getWeightLossGroupDetail = (id, params, thunkAPI) => {
  return axiosClient.get('weight-loss/groups/' + id, {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getWeightLossGroupById = (id, params, thunkAPI) => {
  return axiosClient.get('weight-loss/' + id + '/groups', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getWeightLossMemberById = (id, params, thunkAPI) => {
  return axiosClient.get('weight-loss/' + id + '/members', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
export {
  getWeightLoss,
  getWeightLossDashboard,
  getWeightLossGroup,
  getWeightLossDetail,
  getWeightLossGroupDetail,
  getWeightLossGroupById,
  getWeightLossMemberById,
};
