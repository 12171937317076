import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getChallenges = (params, thunkAPI) => {
  return axiosClient.get('challenge', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getChallengeDetail = (id, thunkAPI) => {
  return axiosClient.get('challenge/' + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postChallenge = (data) => {
  return axiosClient.post('challenge', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putChallenge = (id, data) => {
  return axiosClient.patch('challenge/' + id, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putStatusChallenge = (data) => {
  return axiosClient.patch(
    `challenge/${data?.id}/status/${data?.values?.status}`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    },
  );
};

const exportChallenge = (params, options, thunkAPI) => {
  return axiosClient.get('challenge/export', {
    signal: thunkAPI?.signal,
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const deleteChallengeImage = (id, fileUploadId) => {
  return axiosClient.delete(`challenge/${id}/picture/${fileUploadId}`, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getNoteChallenges = (challengeMemberId, thunkAPI) => {
  return axiosClient.get(`challenge-member/${challengeMemberId}/note`, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export {
  getChallenges,
  getChallengeDetail,
  postChallenge,
  putChallenge,
  putStatusChallenge,
  exportChallenge,
  deleteChallengeImage,
  getNoteChallenges,
};
