import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  getMemberNewme,
  getMemberNewmeDetail,
  getMemberNewmeHealthDetail,
  getNewmeHistory,
} from './newmeAPI';

const initialState = {
  listMember: undefined,
  memberDetail: undefined,
  listHistory: undefined,
  listHealthDetail: undefined,
  status: 'idle',
  error: undefined,
};

export const getMemberNewmeAsync = createAsyncThunk(
  'memberNewme',
  async (params, thunkAPI) => {
    try {
      const response = await getMemberNewme(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getMemberNewmeDetailAsync = createAsyncThunk(
  'memberNewmeDetail',
  async (params, thunkAPI) => {
    try {
      const response = await getMemberNewmeDetail(params, thunkAPI);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getMemberNewmeHealthAsync = createAsyncThunk(
  'memberNewmeHealthDetail',
  async (params, thunkAPI) => {
    try {
      const response = await getMemberNewmeHealthDetail(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getNewmeHistoryAsync = createAsyncThunk(
  'newmeHistory',
  async (params, thunkAPI) => {
    try {
      const response = await getNewmeHistory(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const newmeSlice = createSlice({
  name: 'newme',
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = 'idle';
    },
    clearNewmeHistory: (state) => {
      state.listHistory = undefined;
    },
    clearMemberNewme: (state) => {
      state.listMember = undefined;
    },
    clearMemberNewmeDetail: (state) => {
      state.memberDetail = undefined;
    },
    clearMemberNewmeHealthDetail: (state) => {
      state.listHealthDetail = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // ichallengeluding actions generated by createAsychallengeThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getMemberNewmeAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMemberNewmeAsync.fulfilled, (state, action) => {
        state.listMember = action?.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getMemberNewmeAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          if (
            action?.error?.name !== 'AbortError' &&
            action?.payload?.statusCode !== 401
          ) {
            message.error('Lấy dữ liệu thất bại!');
          }
          state.error = {
            payload: action.payload,
            title: 'getMemberNewme',
          };
        }
      })
      .addCase(getMemberNewmeDetailAsync.pending, (state) => {
        state.status = 'detail_loading';
      })
      .addCase(getMemberNewmeDetailAsync.fulfilled, (state, action) => {
        state.memberDetail = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getMemberNewmeDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getMemberNewmeDetail',
        };
      })
      .addCase(getNewmeHistoryAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNewmeHistoryAsync.fulfilled, (state, action) => {
        state.listHistory = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getNewmeHistoryAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getNewmeHistory',
        };
      })
      .addCase(getMemberNewmeHealthAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getMemberNewmeHealthAsync.fulfilled, (state, action) => {
        state.listHealthDetail = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getMemberNewmeHealthAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getNewmeHistory',
        };
      });
  },
});

export const selectMemberNewme = (state) => state.newme.listMember;
export const selectMemberNewmeDetail = (state) => state.newme.memberDetail;
export const selectNewmeHistory = (state) => state.newme.listHistory;
export const selectNewmeHealthDetail = (state) => state.newme.listHealthDetail;
export const newmeStatus = (state) => state.newme.status;
export const newmeError = (state) => state.newme.error;

export const {
  clearNewmeHistory,
  clearMemberNewme,
  setIdleStatus,
  clearMemberNewmeDetail,
  clearMemberNewmeHealthDetail,
} = newmeSlice.actions;

export default newmeSlice.reducer;
