import { CAMERA_HEIGHT, CAMERA_STATUS, COUNTDOWN_STATUS } from 'common/Camera';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isFirefox } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';

const useCamera = () => {
  const [show, setShow] = useState(false);
  const [isAllow, setAllow] = useState(false);
  const [haveCamera, setHaveCamera] = useState(CAMERA_STATUS.LOADING);
  const [isReloadCam, setIsReloadCam] = useState();
  const [countdownStatus, setCountdownStatus] = useState(COUNTDOWN_STATUS.NONE);
  const [currentVideoRef, setCurrentVideoRef] = useState();
  const [replayCam, setReplayCam] = useState();

  const videoRef = useRef();
  const videoTracker = useRef();

  const drawTimeTakePhoto = (ctx, canvas, isScale) => {
    ctx.font = 'bold 20px Arial';
    if (isScale) {
      ctx.scale(-1, 1);
    }

    let currentTime = moment().format('DD-MM-YYYY HH:mm:ss');
    var currentTimeWidth = ctx.measureText(currentTime).width;

    ctx.fillStyle = 'black';
    ctx.strokeStyle = '#ffffff';
    ctx.lineWidth = 0.5;

    ctx.fillText(
      currentTime,
      canvas.width * 1 - currentTimeWidth - 5,
      canvas.height * 1 - 5,
    );

    ctx.strokeText(
      currentTime,
      canvas.width * 1 - currentTimeWidth - 5,
      canvas.height * 1 - 5,
    );
  };

  const takePhoto = useCallback(
    (callback, { isGetTakePhotoTime, isPreview, isScale, isSquare } = {}) => {
      if (haveCamera !== 0 && isAllow) {
        let videoBox = document.querySelector('.camera-box');
        let { clientWidth: canvasW, clientHeight: canvasH } = videoBox;
        let video = videoRef.current;
        let videoAspectRatio = CAMERA_STATUS / video.clientHeight;
        const canvas = document.createElement('canvas');
        canvas.width = canvasW; // widthImage;
        canvas.height = canvasH; //heightImage;

        let ctx = canvas.getContext('2d');
        if (isScale) {
          ctx.scale(-1, 1);
        }

        let sx = (video.clientWidth - canvasW) / 2;
        if (isSquare) {
          ctx.drawImage(
            video,
            sx * videoAspectRatio,
            0,
            isScale
              ? canvasW * videoAspectRatio * -1
              : canvasW * videoAspectRatio,
            canvasH * videoAspectRatio,
            0,
            0,
            canvasW,
            canvasH,
          );
        } else {
          ctx.drawImage(
            video,
            sx,
            0,
            isScale ? canvasW * -1 : canvasW * 1,
            canvasH,
          );
        }

        if (isGetTakePhotoTime) {
          drawTimeTakePhoto(ctx, canvas, isScale);
        }
        if (isPreview) {
          let previewImg = document.querySelector('.camera-preview-box');
          let img = document.createElement('img');
          img.src = canvas.toDataURL();
          if (previewImg) {
            previewImg.append(img);
          }
        }

        handleCanvas(canvas, callback);
      }
    },
    [haveCamera, isAllow],
  );

  const checkMediaDevicesQuantity = (cb, onError) => {
    navigator?.mediaDevices
      ?.enumerateDevices()
      .then((devices) => {
        if (cb) {
          cb(devices);
        }
      })
      .catch((err) => {
        if (onError) return onError(err);
      });
  };

  const onReloadCamera = () => {
    setIsReloadCam(Math.random());
  };

  const handleCanvas = (canvas, callback) => {
    canvas?.toBlob((blob) => {
      let file = new File([blob], `${uuidv4()}.png`, {
        lastModified: new Date().getTime(),
        type: blob?.type,
      });

      if (file) {
        const fmData = new FormData();
        fmData.append('File', file);
        if (callback && typeof callback === 'function') {
          callback(fmData, file);
        } else {
          console.error('Must have mutate API');
        }
      }
    });
  };

  useEffect(() => {
    //check quantity of media devices on pc or laptop
    checkMediaDevicesQuantity((devices) => {
      if (devices.length === 0) {
        setHaveCamera(CAMERA_STATUS.NO_DEVICE_CAMERA);
      }
    });
  }, [haveCamera]);

  useEffect(() => {
    if (countdownStatus === COUNTDOWN_STATUS.LOADING || replayCam) {
      let previewImg = document.querySelector('.camera-preview-box');
      if (previewImg) {
        previewImg.innerHTML = null;
      }
      setReplayCam(undefined);
    }
  }, [countdownStatus, replayCam]);

  useEffect(() => {
    // listen camera permission status change
    let camera_perm = undefined;
    const handleCameraEvent = () => {
      const allowed =
        camera_perm.state === 'granted' || camera_perm.state === 'prompt';
      setAllow(allowed);
    };

    const checkCameraPermission = async () => {
      camera_perm = await navigator.permissions.query({ name: 'camera' });
      handleCameraEvent();
      camera_perm.addEventListener('change', handleCameraEvent);
    };
    checkCameraPermission();
    return () => {
      if (camera_perm) {
        camera_perm.removeEventListener('change', handleCameraEvent);
      }
    };
  }, []);

  useEffect(() => {
    const listenVideoEnded = () => {
      // listen video ended => turn off media device (camera)
      // check quantity devices to display camera status
      // if have 2 media devices -> change rest camera
      // else display none camera status (0)
      checkMediaDevicesQuantity((devices) => {
        if (devices.length === 0) {
          setHaveCamera(CAMERA_STATUS.NO_DEVICE_CAMERA);
        } else {
          setHaveCamera(CAMERA_STATUS.LOADING);
          onReloadCamera();
        }
      });
    };
    const listenVideoOnLoad = () => {
      //listen video loaded to stop loading video
      setHaveCamera(CAMERA_STATUS.LOADED);
    };

    // reset status loading video
    setHaveCamera(CAMERA_STATUS.LOADING);
    //display camera after click
    if (currentVideoRef) {
      videoRef.current = currentVideoRef.current;
    }
    if (show && videoRef.current) {
      const video = videoRef.current;
      if (!video.srcObject) {
        if (navigator && navigator.mediaDevices) {
          //stream camera to video
          navigator.mediaDevices
            .getUserMedia({
              video: {
                height: CAMERA_HEIGHT,
              },
              audio: false,
            })
            .then((stream) => {
              if (video) {
                if (isFirefox) {
                  setAllow(true);
                }
                video.srcObject = stream;
                videoTracker.current = video.srcObject.getVideoTracks()[0];
                videoTracker.current.addEventListener(
                  'ended',
                  listenVideoEnded,
                );

                videoRef.current.addEventListener(
                  'loadeddata',
                  listenVideoOnLoad,
                );
                video.play();
              }
            })
            .catch((err) => {
              if (isFirefox) {
                setAllow(false);
              }
              if (isAllow) {
                setHaveCamera(0);
              }
              if (err.name === 'NotAllowedError') {
                if (video.srcObject) {
                  video.srcObject = undefined;
                }
              }
            });
        }
      }

      return () => {
        if (video.srcObject) {
          videoTracker.current.removeEventListener('ended', listenVideoEnded);
          videoRef.current.removeEventListener('loadeddata', listenVideoOnLoad);
          video?.srcObject?.getTracks()[0].stop();
          video.srcObject = undefined;
        }
      };
    }
  }, [show, isAllow, isReloadCam, videoRef, currentVideoRef]);

  return {
    show,
    setShow,
    videoRef,
    setCurrentVideoRef,
    countdownStatus,
    setCountdownStatus,
    takePhoto,
    isAllow,
    haveCamera,
    setHaveCamera,
    onReloadCamera,
    setReplayCam,
  };
};

export default useCamera;
