import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getLangs } from './langAPI';

const initialState = {
  langs: undefined,
  lang: 'vi',
  status: 'idle',
};

export const getLangsAsync = createAsyncThunk(
  'lang',
  async (data, thunkAPI) => {
    try {
      const response = await getLangs(data, thunkAPI);
      return response.data;
    } catch (error) {
      return error;
    }
  },
);

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    changeLang: (state, action) => {
      state.lang = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getLangsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getLangsAsync.fulfilled, (state, action) => {
        state.langs = action.payload;
        state.status = 'idle';
      });
  },
});

export const selectLangs = (state) => state.lang.langs;
export const selectLang = (state) => state.lang.lang;

export const { changeLang } = langSlice.actions;

export default langSlice.reducer;
