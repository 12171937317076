import {
  createAsyncThunk,
  // createAsytopicThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  reportVolumnPoint,
  reportUnit,
  reportPurchaser,
  reportUnitPerPurchaser,
  reportPenetration,
} from './productSegAPI';

const initialState = {
  listVolumnPoint: undefined,
  listUnit: undefined,
  listPurchaser: undefined,
  listUnitPerPurchaser: undefined,
  listPenetration: undefined,
  status: 'idle',
  error: undefined,
};

export const reportVolumnPointAsync = createAsyncThunk(
  'reportVolumnPoint',
  async (params, thunkAPI) => {
    try {
      const response = await reportVolumnPoint(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const reportUnitAsync = createAsyncThunk(
  'reportUnit',
  async (params, thunkAPI) => {
    try {
      const response = await reportUnit(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const reportPurchaserAsync = createAsyncThunk(
  'reportPurchaser',
  async (params, thunkAPI) => {
    try {
      const response = await reportPurchaser(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const reportUnitPerPurchaserAsync = createAsyncThunk(
  'reportUnitPerPurchaser',
  async (params, thunkAPI) => {
    try {
      const response = await reportUnitPerPurchaser(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const reportPenetrationAsync = createAsyncThunk(
  'reportPenetration',
  async (params, thunkAPI) => {
    try {
      const response = await reportPenetration(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const productSegSlice = createSlice({
  name: 'productSeg',
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = 'idle';
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // ieventluding actions generated by createAsyeventThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(reportVolumnPointAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(reportVolumnPointAsync.fulfilled, (state, action) => {
        state.listVolumnPoint = action.payload.data;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(reportVolumnPointAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getVersions',
        };
      })
      .addCase(reportUnitAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(reportUnitAsync.fulfilled, (state, action) => {
        state.listUnit = action.payload.data;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(reportUnitAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getVersions',
        };
      })
      .addCase(reportPurchaserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(reportPurchaserAsync.fulfilled, (state, action) => {
        state.listPurchaser = action.payload.data;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(reportPurchaserAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getVersions',
        };
      })
      .addCase(reportUnitPerPurchaserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(reportUnitPerPurchaserAsync.fulfilled, (state, action) => {
        state.listUnitPerPurchaser = action.payload.data;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(reportUnitPerPurchaserAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getVersions',
        };
      })
      .addCase(reportPenetrationAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(reportPenetrationAsync.fulfilled, (state, action) => {
        state.listPenetration = action.payload.data;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(reportPenetrationAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getVersions',
        };
      });
  },
});

export const selectVolumnPoint = (state) => state.productSeg.listVolumnPoint;
export const selectUnit = (state) => state.productSeg.listUnit;
export const selectPurchaser = (state) => state.productSeg.listPurchaser;
export const selectUnitPerPurchaser = (state) =>
  state.productSeg.listUnitPerPurchaser;
export const selectPenetration = (state) => state.productSeg.listPenetration;
export const productSegStatus = (state) => state.productSeg.status;
export const productSegError = (state) => state.productSeg.error;

export const { setIdleStatus } = productSegSlice.actions;

export default productSegSlice.reducer;
