import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  getMemberListFileSurvey,
  getMemberListSurvey,
  getQuestionListSurvey,
  getStatisticSurvey,
  getSurveyDetail,
  getSurveys,
  postSurvey,
} from './surveyAPI';
import _ from 'lodash';

const initialState = {
  list: undefined,
  detail: undefined,
  status: 'idle',
  error: undefined,
  statistic: undefined,
  quesList: undefined,
  memList: undefined,
  memFileList: undefined,
};

export const getSurveysAsync = createAsyncThunk(
  'surveys',
  async (params, thunkAPI) => {
    try {
      const response = await getSurveys(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getSurveyDetailAsync = createAsyncThunk(
  'surveyDetail',
  async (id, thunkAPI) => {
    try {
      const response = await getSurveyDetail(id, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const postSurveyAsync = createAsyncThunk(
  'postSurvey',
  async (data, thunkAPI) => {
    try {
      const response = await postSurvey(data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getStatisticSurveyAsync = createAsyncThunk(
  'statisticSurvey',
  async (id, thunkAPI) => {
    try {
      const response = await getStatisticSurvey(id, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getQuesListSurveyAsync = createAsyncThunk(
  'quesListSurvey',
  async (params, thunkAPI) => {
    try {
      const response = await getQuestionListSurvey(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getMemberListSurveyAsync = createAsyncThunk(
  'memberListSurvey',
  async (params, thunkAPI) => {
    try {
      const response = await getMemberListSurvey(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getMemberFileListSurveyAsync = createAsyncThunk(
  'memberFileListSurvey',
  async (params, thunkAPI) => {
    try {
      const response = await getMemberListFileSurvey(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const surveySlice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = 'idle';
    },
    setLoadingUploadStatus: (state) => {
      state.status = 'loading_upload';
    },
    clearSurveyDetail: (state) => {
      state.detail = undefined;
    },
    clearSurveys: (state) => {
      state.list = undefined;
    },
    clearQuesListSurveys: (state) => {
      state.quesList = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // isurveyluding actions generated by createAsysurveyThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getSurveysAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSurveysAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getSurveysAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getSurveys',
        };
      })
      .addCase(getSurveyDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSurveyDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getSurveyDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getSurveyDetail',
        };
      })
      .addCase(postSurveyAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postSurveyAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(postSurveyAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Thêm mới sự kiện thất bại');
        }
        state.error = {
          payload: action.payload,
          title: 'addSurvey',
        };
      })
      .addCase(getStatisticSurveyAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getStatisticSurveyAsync.fulfilled, (state, action) => {
        state.statistic = action.payload.data;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getStatisticSurveyAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'statisticSurvey',
        };
      })
      .addCase(getQuesListSurveyAsync.pending, (state) => {
        state.status = 'loading_quesList';
      })
      .addCase(getQuesListSurveyAsync.fulfilled, (state, action) => {
        let prevData = state.quesList?.data || [];
        let clone = _.cloneDeep(action.payload);
        clone.data = [...prevData, ...action.payload.data];
        state.quesList = clone;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getQuesListSurveyAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'quesListSurvey',
        };
      })
      .addCase(getMemberListSurveyAsync.pending, (state) => {
        state.status = 'loading_memList';
      })
      .addCase(getMemberListSurveyAsync.fulfilled, (state, action) => {
        state.memList = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getMemberListSurveyAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'memberListSurvey',
        };
      })
      .addCase(getMemberFileListSurveyAsync.pending, (state) => {
        state.status = 'loading_memFileList';
      })
      .addCase(getMemberFileListSurveyAsync.fulfilled, (state, action) => {
        state.memFileList = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getMemberFileListSurveyAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'memberFileListSurvey',
        };
      });
  },
});

export const selectSurveys = (state) => state.survey.list;
export const selectSurveyDetail = (state) => state.survey.detail;
export const selectStatisticSurvey = (state) => state.survey.statistic;
export const selectQuesListSurvey = (state) => state.survey.quesList;
export const selectMemListSurvey = (state) => state.survey.memList;
export const selectMemFileListSurvey = (state) => state.survey.memFileList;
export const surveysStatus = (state) => state.survey.status;
export const surveysError = (state) => state.survey.error;

export const {
  clearSurveyDetail,
  clearSurveys,
  clearQuesListSurveys,
  setIdleStatus,
  setLoadingUploadStatus,
} = surveySlice.actions;

export default surveySlice.reducer;
