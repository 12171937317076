import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  getChallengeDetail,
  getChallenges,
  postChallenge,
  putStatusChallenge,
} from './challengeAPI';

const initialState = {
  list: undefined,
  detail: undefined,
  status: 'idle',
  error: undefined,
};

export const getChallengesAsync = createAsyncThunk(
  'challenges',
  async (params, thunkAPI) => {
    try {
      const response = await getChallenges(params, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getChallengeDetailAsync = createAsyncThunk(
  'challengeDetail',
  async (id, thunkAPI) => {
    try {
      const response = await getChallengeDetail(id, thunkAPI);
      // The value we return becomes the `fulfilled` action payload
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const postChallengeAsync = createAsyncThunk(
  'postChallenge',
  async (data, thunkAPI) => {
    try {
      const response = await postChallenge(data, thunkAPI);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const putChallengeStatusAsync = createAsyncThunk(
  'putChallengeStatus',
  async (data, thunkAPI) => {
    try {
      const response = await putStatusChallenge(data, thunkAPI);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const challengeSlice = createSlice({
  name: 'challenge',
  initialState,
  reducers: {
    setIdleStatus: (state) => {
      state.status = 'idle';
    },
    clearChallengeDetail: (state) => {
      state.detail = undefined;
    },
    clearChallenges: (state) => {
      state.list = undefined;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // ichallengeluding actions generated by createAsychallengeThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getChallengesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getChallengesAsync.fulfilled, (state, action) => {
        state.list = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getChallengesAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getChallenges',
        };
      })
      .addCase(getChallengeDetailAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getChallengeDetailAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'idle';
        state.error = undefined;
      })
      .addCase(getChallengeDetailAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Lấy dữ liệu thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'getChallengeDetail',
        };
      })
      .addCase(postChallengeAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(postChallengeAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(postChallengeAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Thêm mới sự kiện thất bại');
        }
        state.error = {
          payload: action.payload,
          title: 'addChallenge',
        };
      })
      .addCase(putChallengeStatusAsync.pending, (state) => {
        state.status = 'update_status_loading';
      })
      .addCase(putChallengeStatusAsync.fulfilled, (state, action) => {
        state.detail = action.payload;
        state.status = 'done';
        state.error = undefined;
      })
      .addCase(putChallengeStatusAsync.rejected, (state, action) => {
        state.status = 'idle';
        if (
          action?.error?.name !== 'AbortError' &&
          action?.payload?.statusCode !== 401
        ) {
          message.error('Đổi trạng thái thất bại!');
        }
        state.error = {
          payload: action.payload,
          title: 'putChallengeStatus',
        };
      });
  },
});

export const selectChallenges = (state) => state.challenges.list;
export const selectChallengeDetail = (state) => state.challenges.detail;
export const challengesStatus = (state) => state.challenges.status;
export const challengesError = (state) => state.challenges.error;

export const { clearChallengeDetail, clearChallenges, setIdleStatus } =
  challengeSlice.actions;

export default challengeSlice.reducer;
