import axiosClient from '../axiosClientNewme2023';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getNewsFeedOfficial = (params) => {
  return axiosClient.get('admin/news-feeds/news-feed-official', {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const addNewsFeedOfficial = (data) => {
  return axiosClient.post('admin/news-feeds/news-feed-official', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const updateNewsFeedOfficial = (id, data) => {
  return axiosClient.patch(`admin/news-feeds/update/${id}`, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const updateStatusNewsFeedOfficial = (id, data) => {
  return axiosClient.patch(`admin/news-feeds/update-status/${id}`, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getNewsFeedTeamOfficial = (params) => {
  return axiosClient.get('admin/news-feeds/news-feed-team-official', {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getNewsFeedDetail = (id, params) => {
  return axiosClient.get('admin/news-feeds/' + id, {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getMemberCreator = (params) => {
  return axiosClient.get('admin/news-feeds/member-creator', {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postApproval = (data) => {
  return axiosClient.post('admin/news-feeds/approval', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const newsFeedExport = (url, params) => {
  return axiosClient.get(url, {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
    responseType: 'blob',
  });
};

const getTeams = (params) => {
  return axiosClient.get('admin/team', {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getTeamDetail = ({ id, params }) => {
  return axiosClient.get('admin/team/' + id, {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getRounds = (params) => {
  return axiosClient.get('admin/round', {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getStatistic = (params) => {
  return axiosClient.get('admin/statistic/dashboard', {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getChartData = (params) => {
  return axiosClient.get('admin/statistic/dashboard-chart', {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getTeamStockPhoto = (params) => {
  return axiosClient.get('admin/media-files', {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const disableMediaFile = (data) => {
  return axiosClient.post('admin/media-files/disable-media-file', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getNewsFeedTeam = (params) => {
  return axiosClient.get('admin/news-feeds/news-feed-team', {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const disableNewsFeed = (data) => {
  return axiosClient.post('admin/news-feeds/disable-news-feed', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getMemberList = (params) => {
  return axiosClient.get('admin/member', {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const pinNewsFeed = (id) => {
  return axiosClient.post(`admin/news-feeds/${id}/pin`, null, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getDashboardOverview = () => {
  return axiosClient.get('admin/dashboard/dashboard-overview', {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getSubChartOverview = (params) => {
  return axiosClient.get('admin/dashboard/sub-chart-overview', {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getAreas = () => {
  return axiosClient.get('admin/area', {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getStatisticRound = (params) => {
  return axiosClient.get('admin/dashboard/statistic-round', {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getStatisticRoundPhase = (params) => {
  return axiosClient.get('admin/dashboard/statistic-round-phase', {
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getStatisticMember = () => {
  return axiosClient.get('admin/dashboard/statistic-member', {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export {
  getNewsFeedOfficial,
  getNewsFeedTeamOfficial,
  getNewsFeedDetail,
  addNewsFeedOfficial,
  updateNewsFeedOfficial,
  updateStatusNewsFeedOfficial,
  getMemberCreator,
  postApproval,
  newsFeedExport,
  getTeams,
  getTeamDetail,
  getRounds,
  getStatistic,
  getChartData,
  getTeamStockPhoto,
  disableMediaFile,
  getNewsFeedTeam,
  disableNewsFeed,
  getMemberList,
  pinNewsFeed,
  getDashboardOverview,
  getSubChartOverview,
  getAreas,
  getStatisticRound,
  getStatisticRoundPhase,
  getStatisticMember,
};
