import axiosClient from '../axiosClientNewme';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getMemberNewme = (params, thunkAPI) => {
  return axiosClient.get('admin/new-me-member/get-all', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getMemberNewmeDetail = (id, params, thunkAPI) => {
  return axiosClient.get('admin/new-me-member/' + id, {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getMemberNewmeHealthDetail = (id, thunkAPI) => {
  return axiosClient.get('admin/new-me-health-information/' + id + '/detail', {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};
const getNewmeHistory = (id, params, thunkAPI) => {
  return axiosClient.get('admin/new-me-health-information/' + id, {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const exportNewmeNCC = (params, options) => {
  return axiosClient.get('admin/exports/customers', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const exportNewmeNCO = (params, options) => {
  return axiosClient.get('admin/exports/operators', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const exportNewmeTab = (params, options) => {
  return axiosClient.get('admin/exports/tabs', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export {
  getMemberNewme,
  getMemberNewmeDetail,
  getNewmeHistory,
  exportNewmeNCC,
  exportNewmeNCO,
  exportNewmeTab,
  getMemberNewmeHealthDetail,
};
