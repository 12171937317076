import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getFeatures = (params, thunkAPI) => {
  return axiosClient.get('feature', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putFeatures = (data) => {
  return axiosClient.patch('feature/update-status', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const exportFeatures = (params, options) => {
  return axiosClient.get('feature/export', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

export { getFeatures, putFeatures, exportFeatures };
