import axios from 'axios';

const version = 'v1/';
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_NEWME2023 + version,
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      localStorage.removeItem('auth');
      localStorage.removeItem('token');
      window.location.replace('/');
    }
    return Promise.reject(error);
  },
);

export default instance;
