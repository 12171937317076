import axiosClient from '../axiosClient';

const getToken = () => {
  return (
    (localStorage?.auth && JSON.parse(localStorage.auth)?.accessToken) ||
    undefined
  );
};

const getStickers = (params, thunkAPI) => {
  return axiosClient.get('sticker', {
    signal: thunkAPI?.signal,
    params,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const getStickerDetail = (id, thunkAPI) => {
  return axiosClient.get('sticker/' + id, {
    signal: thunkAPI?.signal,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const postSticker = (data) => {
  return axiosClient.post('sticker', data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const putSticker = ({ id, data }) => {
  return axiosClient.patch('sticker/' + id, data, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const deleteSticker = (id) => {
  return axiosClient.delete('sticker/' + id, {
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const exportSticker = (params, options) => {
  return axiosClient.get('sticker/export', {
    params,
    ...options,
    headers: {
      Authorization: 'Bearer ' + getToken(),
    },
  });
};

const activeSticker = ({ id }) => {
  return axiosClient.patch(
    'sticker/' + id + '/activate',
    {},
    {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    },
  );
};

const deActiveSticker = ({ id }) => {
  return axiosClient.patch(
    'sticker/' + id + '/de-activate',
    {},
    {
      headers: {
        Authorization: 'Bearer ' + getToken(),
      },
    },
  );
};

export {
  getStickers,
  getStickerDetail,
  postSticker,
  putSticker,
  deleteSticker,
  exportSticker,
  activeSticker,
  deActiveSticker,
};
